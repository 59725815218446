import React, { useState } from "react";
import { Button, Text } from "..";
import locale from "localization";
import styles from "./filter.module.scss";
import classNames from "classnames";

const Filter = ({ children, submit, clear, close, filterCount, disabled }) => {
  const [active, setActive] = useState(false);
  return (
    <div>
      {active && (
        <div
          className={styles.overlay}
          onClick={() => {
            setActive(false);
            close && close();
          }}
        ></div>
      )}

      <Button
        className={classNames(styles.button, {
          [`${styles.active}`]: active,
        })}
        onClick={() => {
          setActive(true);
        }}
      >
        <div className={styles.text}>
          <span className={styles.filterIcon}>
            {/* <Image src={FilterIcon} className={styles.filterIconImage} /> */}
            <span className="icon icon-filter" />
          </span>
          {locale.filters}
          {filterCount > 0 && <div className={styles.count}>{filterCount}</div>}
        </div>
      </Button>
      <div
        className={classNames(styles.filterContent, {
          [`${styles.active}`]: active,
          [`${styles.inActive}`]: !active,
        })}
      >
        <Text strong className={styles.title}>
          {locale.filters}
        </Text>
        {children}
        <div className={styles.action}>
          <Button
            textStyle
            onClick={() => {
              clear();
              setActive(false);
            }}
          >
            {locale.clearAll}
          </Button>
          <Button
            outlineSquare
            disabled={disabled}
            onClick={() => {
              submit();
              setActive(false);
            }}
          >
            {locale.applyFilters}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
