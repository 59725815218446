import { CircularProgress } from "@material-ui/core";

import React, { useMemo, useState } from "react";
import { ActionButton, Autocomplete, Field, FuelIcon, Text, Title } from "components/commons";

import { InputLitre } from "components/field";
import locale from "localization";
import InsufficientErrorPage from "./insufficient-error-page";
import { initialState as formState } from "./validate-payment-details.state";
import styles from "./validate-payment.module.scss";
import ValidatePaymentConfirmPage from "./validate-payment-confirm-page";
import PaymentErrorPage from "./payment-error-page";
import PaymentSuccessPage from "./payment-success-page";
import ProceedToGasModal from "./proceed-to-gas-modal";
import { useApi, useForm, useModal, useMount } from "hooks";
import { prettifyDispenseType, prettifyProduct } from "utils/pretty.utils";
import { formatVolume, handleRequest, parseLiter } from "utils";
import { getFuelBalance, redeemFuelCode, updateRedemption } from "apis";
import _ from "lodash";
import { DispenseType, ErrorCode } from "enums";
import classNames from "classnames";

const ValidatePaymentDetails = (props, { initialState }) => {
  const { details, setPaymentDetails, setDetails, clearForm, onSuccessCallback, onActiveStepCB } =
    props;
  const [showGasUp, setGasUp] = useState(false);
  const [showProceedToPayment, setProceedToPayment] = useState(false);
  const [showSuccessPayment, setSuccessPayment] = useState(false);
  const [showPaymentError, setPaymentError] = useState(false);
  const [showInsufficientError, setInsufficientError] = useState(false);
  const [fuelBalance, setFuelBalance] = useState(null);
  const [redemptionId, setRedemptionId] = useState(null);
  const [redemptionResponse, setRedemptionResponse] = useState(null);
  const confirmModalGasUp = useModal();

  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const { fields, modifyField, isFormSubmittable, getFormValues, applyFieldErrors } = useForm({
    initialState: form,
  });

  const { request: getFuelBalanceRequest, loading: fetchingFuelBalance } = useApi({
    api: getFuelBalance,
    params: {
      fleetId: details.fleetId,
    },
    pageError: true,
  });

  const { request: redeemFuelCodeRequest, loading: redeemingFuelCode } = useApi({
    api: redeemFuelCode,
    params: {
      fleetId: details.fleetId,
    },
    pageError: false,
    modalError: false,
  });
  const { request: updateRedemptionORRequest, loading: updatingRedemptionOR } = useApi({
    api: updateRedemption,

    pageError: false,
    modalError: false,
  });

  useMount(async () => {
    handleRequest(async () => {
      const res = await getFuelBalanceRequest();
      if (res && res.length > 0) {
        const balanceDetails = _.find(res, (item) => {
          return item.productCode === details.productCode;
        });
        if (balanceDetails) {
          setFuelBalance(balanceDetails.fuelBalance);
        } else {
          setFuelBalance(formatVolume(0));
        }
      }
    });
  });

  const onLiterVolumeChange = (name, value) => {
    const literVolumeString = value.value;
    const literVolumeFloat = parseFloat(parseLiter(literVolumeString));
    const fuelCodeBalance = +details.literVolume;
    modifyField(name, { value: literVolumeString });

    if (literVolumeString && parseLiter(literVolumeFloat) < 1) {
      return applyFieldErrors({
        literVolume: locale.minimumAllowedRedemption1Liter,
      });
    }

    if (literVolumeFloat > fuelBalance || literVolumeFloat > fuelCodeBalance) {
      const balance = fuelBalance < fuelCodeBalance ? fuelBalance : fuelCodeBalance;

      applyFieldErrors({
        literVolume: locale.populate(locale.maximumAllowedRedemption, [formatVolume(balance)]),
      });
    }
  };

  const confirm = () => {
    handleRequest(
      async () => {
        const { literVolume, dispensedTo } = getFormValues();
        const driverId = details.driverId ? { driverId: details.driverId } : {};
        const res = await redeemFuelCodeRequest({
          stationCode: details.stationCode,
          fuelCode: details.fuelCode,
          literVolume: parseLiter(literVolume),
          dispensedTo: dispensedTo || DispenseType.Vehicle,
          productCode: details.productCode,
          vehicleId: details.vehicleId,
          ...driverId,
        });

        if (res) {
          setRedemptionResponse(res);
          setRedemptionId(res.redemptionId);
          setProceedToPayment(false);
          setSuccessPayment(true);

          return;
        }
        onSuccessCallback();
        setProceedToPayment(false);
        setSuccessPayment(false);
        setPaymentError(true);
      },
      [redemptionResponse, redemptionId, setRedemptionId],
      (err) => {
        setProceedToPayment(false);
        switch (err.data?.errorCode) {
          case ErrorCode.InsufficientBalance:
            setProceedToPayment(false);
            setSuccessPayment(false);
            setPaymentError(false);
            setInsufficientError(true);
            break;
          case ErrorCode.NoProductFuelBalance:
            setProceedToPayment(false);
            setSuccessPayment(false);
            setPaymentError(true);
            break;
          default:
            setProceedToPayment(false);
            setSuccessPayment(false);
            setPaymentError(true);
        }
      }
    );
  };

  const reset = () => {
    clearForm();
    setPaymentDetails(null);
  };

  const handleProceedGasModal = (status) => {
    setGasUp(status);
  };

  const handleBackProceedToPayment = () => {
    setProceedToPayment(false);
    setGasUp(true);
  };

  const handleBackToValidatePayment = () => {
    clearForm();
    setDetails(null);
    setPaymentDetails(false);
    onActiveStepCB(0);
  };

  const onSubmitORCB = (orField) => {
    handleRequest(async () => {
      const res = await updateRedemptionORRequest({
        redemptionId,
        body: { orNumber: orField.value },
      });

      if (res) {
        setRedemptionId(res.redemptionId);
        setSuccessPayment(false);
        setDetails(null);
        reset();
        onActiveStepCB(0);
        return;
      }
      onSuccessCallback();
      setPaymentError(true);
    }, [redemptionId, setRedemptionId]);
  };

  if (showSuccessPayment) {
    onActiveStepCB(2);
    return (
      <PaymentSuccessPage
        redemptionId={redemptionId}
        transactionDetails={redemptionResponse}
        literVolume={parseLiter(fields.literVolume.value)}
        dispensedTo={fields.dispensedTo.value}
        reset={reset}
        details={details}
        setDetails={setDetails}
        closable={false}
        loading={updatingRedemptionOR}
        onSubmitOR={onSubmitORCB}
      />
    );
  }
  if (showPaymentError) {
    onActiveStepCB(2);
    return <PaymentErrorPage handleBackToValidatePayment={handleBackToValidatePayment} />;
  }

  if (showInsufficientError) {
    return <InsufficientErrorPage details={details} />;
  }

  if (showGasUp) {
    onActiveStepCB(1);
    return (
      <>
        <div className={styles.detailsContainer}>
          <div className={styles.contentPayment}>
            <div>
              <Title className={styles.subtitle}>{locale.actualGasUpDetails}</Title>
              <div className={styles.container}>
                <Field {...fields.literVolume}>
                  <InputLitre {...fields.literVolume} onChange={onLiterVolumeChange} />
                </Field>
                <Field {...fields.dispensedTo}>
                  <Autocomplete
                    {...fields.dispensedTo}
                    options={mapOptions(
                      [DispenseType.Vehicle, DispenseType.DrumContainer],
                      prettifyDispenseType
                    )}
                    onChange={modifyField}
                  />
                </Field>
              </div>
            </div>
            <ActionButton
              left
              secondaryIcon={<span className="icon-back" />}
              items={[
                {
                  onClick: () => {
                    setPaymentDetails(false);
                    setGasUp(false);
                    onActiveStepCB(0);
                  },
                  text: locale.back,
                },
                {
                  onClick: async () => {
                    setProceedToPayment(true);
                    setGasUp(false);
                  },
                  disabled: !isFormSubmittable,
                  text: locale.proceedToPayment,
                },
              ]}
            />
          </div>
          <div className={styles.contentDetails}>
            <Title className={styles.subtitle}>{fields.fuelCode.label}</Title>
            <Text className={styles.labelFuelCode}>{details.fuelCode}</Text>
            <div className={styles.container}>
              <Title className={styles.subtitle}>{fields.productType.label}</Title>
              <br />
              {fetchingFuelBalance ? (
                <CircularProgress />
              ) : (
                <div className={styles.productTypeContainer}>
                  <FuelIcon type={details.productCode} />
                  <div className={styles.productTypeDetails}>
                    <Text className={styles.textTitle}>{prettifyProduct(details.productCode)}</Text>
                    <Text className={styles.textBalance}>
                      {`${locale.fleetProductBalance}: `}
                      <span
                        className={classNames(styles.textBalance, {
                          [`${styles.boldRed}`]: fuelBalance < details.literVolume,
                        })}
                      >
                        {formatVolume(fuelBalance)}
                      </span>
                    </Text>
                    <Text className={styles.textBalance}>
                      {`${locale.fuelCodeRemainingLimit}: `}
                      <span
                        className={classNames(styles.textBalance, {
                          [`${styles.boldRed}`]: details.literVolume <= fuelBalance,
                        })}
                      >
                        {formatVolume(details.literVolume)}
                      </span>
                    </Text>
                  </div>
                </div>
              )}
            </div>
            <Title className={styles.subtitle}>{locale.customerDetails}</Title>
            <div className={styles.table}>
              <div className={styles.row}>
                <label>{locale.businessName}</label>
                <label>{details.fleet?.businessName || ""}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.eligibleDriver}</label>
                <label>{details?.driverName || locale.any}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.driverId}</label>
                <label>{details?.driverLicenseId || locale.any}</label>
              </div>
              <div className={styles.row}>
                <label>{locale.eligibleVehicle}</label>
                <label
                  className={classNames({
                    italic: !details.vehiclePlateNumber,
                  })}
                >
                  {details.vehiclePlateNumber || locale.any}
                </label>
              </div>
              <div className={styles.row}>
                <label>{locale.vehicleModel}</label>
                <label
                  className={classNames({
                    italic: !details.vehicleModel,
                  })}
                >
                  {details.vehicleModel || locale.any}
                </label>
              </div>
              <div className={styles.row}>
                <label>{locale.remarks}</label>
                <label
                  className={classNames(styles.remarks, {
                    italic: !details.remarks,
                  })}
                >
                  {details.remarks || locale.any}
                </label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (showProceedToPayment) {
    onActiveStepCB(2);
    return (
      <ValidatePaymentConfirmPage
        confirm={confirm}
        literVolume={parseLiter(fields.literVolume.value)}
        dispensedTo={fields.dispensedTo.value}
        loading={redeemingFuelCode}
        details={details}
        handleBackProceedToPayment={handleBackProceedToPayment}
      />
    );
  }

  return (
    <>
      <div className={styles.detailsContainer}>
        <div className={styles.contentPayment}>
          <div className={styles.fuelCodeContent}>
            <Title className={styles.subtitle}>{locale.validateFuelCodeAndValume}</Title>
            <Text label>{locale.pleaseEnterFuelCode}</Text>
            <div className={styles.container}>
              <Field {...fields.fuelCode}>
                <Text className={styles.labelFuelCode}>{details.fuelCode}</Text>
              </Field>
              <Field {...fields.productType}>
                {fetchingFuelBalance ? (
                  <CircularProgress />
                ) : (
                  <div className={styles.productTypeContainer}>
                    <FuelIcon type={details.productCode} />
                    <div className={styles.productTypeDetails}>
                      <Text className={styles.textTitle}>
                        {prettifyProduct(details.productCode)}
                      </Text>
                      <Text className={styles.textBalance}>
                        {`${locale.fleetProductBalance}: `}
                        <span
                          className={classNames(styles.textBalance, {
                            [`${styles.boldRed}`]: fuelBalance < details.literVolume,
                          })}
                        >
                          {formatVolume(fuelBalance)}
                        </span>
                      </Text>
                      <Text className={styles.textBalance}>
                        {`${locale.fuelCodeRemainingLimit}: `}
                        <span
                          className={classNames(styles.textBalance, {
                            [`${styles.boldRed}`]: details.literVolume <= fuelBalance,
                          })}
                        >
                          {formatVolume(details.literVolume)}
                        </span>
                      </Text>
                    </div>
                  </div>
                )}
              </Field>
            </div>
          </div>
          <ActionButton
            left
            items={[
              {
                onClick: () => {
                  clearForm();
                  setDetails(null);
                  setPaymentDetails(false);
                },
                text: locale.cancel,
              },
              {
                onClick: async () => {
                  confirmModalGasUp.show();
                },
                text: locale.proceedToGasUp,
              },
            ]}
          />
        </div>
        <div className={styles.contentDetails}>
          <Title className={styles.subtitle}>{locale.customerDetails}</Title>
          <div className={styles.table}>
            <div className={styles.row}>
              <label>{locale.businessName}</label>
              <label>{details.fleet?.businessName || ""}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.eligibleDriver}</label>
              <label>{details?.driverName || locale.any}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.driverId}</label>
              <label>{details?.driverLicenseId || locale.any}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.eligibleVehicle}</label>
              <label
                className={classNames({
                  italic: !details.vehiclePlateNumber,
                })}
              >
                {details.vehiclePlateNumber || locale.any}
              </label>
            </div>
            <div className={styles.row}>
              <label>{locale.vehicleModel}</label>
              <label
                className={classNames({
                  italic: !details.vehicleModel,
                })}
              >
                {details.vehicleModel || locale.any}
              </label>
            </div>
            <div className={styles.row}>
              <label>{locale.remarks}</label>
              <label
                className={classNames(styles.remarks, {
                  italic: !details.remarks,
                })}
              >
                {details.remarks || locale.any}
              </label>
            </div>
          </div>
        </div>
      </div>
      <ProceedToGasModal handleProceedGasModal={handleProceedGasModal} {...confirmModalGasUp} />
    </>
  );
};
const mapOptions = (values = [], prettifier = () => {}) => {
  return [
    ...values.map((value) => ({
      label: prettifier(value),
      value,
    })),
  ];
};

export default ValidatePaymentDetails;
