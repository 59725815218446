import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getStationPayments = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.StationPayments(stationId)}`, query);
  return res.data;
};

export const getMerchantStationPayments = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.MerchantStationPayments(stationId)}`, query);
  return res.data;
};

export const voidPayment = async ({ ...body }) => {
  const res = await Post(`ms-fleet/payment/void`, body);
  return res.data;
};
