import {
  Autocomplete,
  DateRange,
  Field,
  Filter,
  GroupCheckbox,
  SearchField,
  Button,
} from "components/commons";
import { Products, SettlementStatus } from "enums";
import React, { useCallback } from "react";
import { prettifyProduct, prettifySettlementStatus } from "utils/pretty.utils";
import styles from "./payment-history-filter.module.scss";
import locale from "localization";
import classNames from "classnames";
import { useMemo } from "react";
import { redemptionStatusOptions } from "data/select-options";

const PaymentHistoryFilter = ({
  filterState,
  onDateRangeChange,
  onProductChange,
  onSearchChange,
  onSearch,
  submitFilter,
  resetFilter,
  clearFilter,
  placeholder,
  onRedemptionStatusChangeCb,
  filterCount,
  modifyFilters,
  exportReport,
  exporting,
}) => {
  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onProductChangeCb = useCallback(
    (value) => {
      if (onProductChange) return onProductChange(value);
    },
    [onProductChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  const isFormValid = useMemo(() => {
    if (filterState.productCodes?.length) {
      return true;
    }
    return false;
  }, [filterState.productCodes]);

  return (
    <div className={styles.filter}>
      <Filter
        submit={submitFilter}
        close={resetFilter}
        clear={clearFilter}
        filterCount={filterCount}
        disabled={!isFormValid}
      >
        <Field className={styles.date} label={locale.date} labelPosition="left">
          <DateRange
            className={styles.dateRange}
            value={{
              startDate: filterState.startDate,
              endDate: filterState.endDate,
            }}
            onChange={onDateRangeChangeCb}
          />
        </Field>
        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.fieldSelect}
          label={locale.status}
        >
          <Autocomplete
            capitalize={false}
            name={"redemptionStatuses"}
            hasAll
            multiple
            placeholder="Select Status"
            value={filterState.redemptionStatuses}
            options={redemptionStatusOptions}
            onChange={(name, { value }) => {
              modifyFilters({ [name]: value });
            }}
          />
        </Field>
        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.fieldSelect}
          label={locale.settlementStatus}
        >
          <Autocomplete
            name={"settlementStatuses"}
            hasAll
            multiple
            placeholder="Select Settlement Status"
            value={filterState?.settlementStatuses}
            options={[
              {
                label: prettifySettlementStatus(SettlementStatus.ForSettlement),
                value: SettlementStatus.ForSettlement,
              },
              {
                label: prettifySettlementStatus(SettlementStatus.ForProcessing),
                value: SettlementStatus.ForProcessing,
              },
              {
                label: prettifySettlementStatus(SettlementStatus.Processed),
                value: SettlementStatus.Processed,
              },
            ]}
            onChange={(_, { value }) => {
              modifyFilters({ settlementStatuses: value });
            }}
          />
        </Field>
        <GroupCheckbox
          className={styles.productCode}
          title={locale.availableProducts}
          options={[
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.diesel)}></div>
                  {prettifyProduct(Products.Diesel)}
                </div>
              ),
              value: Products.Diesel,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas91)}></div>
                  {prettifyProduct(Products.Gas91)}
                </div>
              ),
              value: Products.Gas91,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas95)}></div>
                  {prettifyProduct(Products.Gas95)}
                </div>
              ),
              value: Products.Gas95,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas97)}></div>
                  {prettifyProduct(Products.Gas97)}
                </div>
              ),
              value: Products.Gas97,
            },
          ]}
          value={filterState.productCodes}
          onChange={(name, { value }) => {
            onProductChangeCb(value);
          }}
        />
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={placeholder}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default PaymentHistoryFilter;
