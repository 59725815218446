import { Button, FuelIcon, Image, Title, Text, Field, TextField } from "components/commons";
import React, { useMemo } from "react";
import locale from "localization";
import { formatNumber, formatAmount } from "utils";
import styles from "./payment-success-page.module.scss";
import { prettifyProduct } from "utils/pretty.utils";
import { initialState as formState } from "./payment-or-details.state";
import { PaymentSuccess } from "images";
import classNames from "classnames";
import { useForm } from "hooks";

const PaymentSuccessPage = ({
  loading,
  dispensedTo,
  literVolume,
  redemptionId,
  onSubmitOR,
  details,
  transactionDetails,
  setDetails,
  reset,
  initialState,
  // ...modal
}) => {
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);
  const { fields, modifyField, isFormSubmittable } = useForm({
    initialState: form,
  });
  return (
    <div className={styles.paymentSuccessContainer}>
      <div className={styles.confirmation}>
        <Text className={styles.headerPaymentSuccessTitle} strong>
          {locale.paymentSuccess}
        </Text>
        <div className={styles.titleContainer}>
          <Image src={PaymentSuccess} className={styles.successIcon} />
          <div className={styles.titleContent}>
            <Title medium className={styles.title}>
              <locale.Populate
                text={literVolume === 1 ? locale.nLiter : locale.nLiters}
                items={[formatNumber(literVolume)]}
              />
            </Title>
            <Text>
              <locale.Populate
                text={locale.ofText}
                className={styles.fuel}
                items={[
                  <span className={styles.fuel}>
                    <FuelIcon className={styles.icon} type={details.productCode} />
                    {prettifyProduct(details.productCode)}
                  </span>,
                ]}
              />
            </Text>
            <Text>{locale.hasBeenSuccessfullyRedeemed}</Text>
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.row}>
            <label>{locale.businessName}</label>
            <label>{details.fleet?.businessName || ""}</label>
          </div>
          <div className={styles.row}>
            <label>{locale.transactionId}</label>
            <label>{transactionDetails?.redemptionId || locale.any}</label>
          </div>
          <div className={styles.row}>
            <label>{locale.eligibleVehicle}</label>
            <label
              className={classNames({
                italic: !details.vehiclePlateNumber,
              })}
            >
              {details.vehiclePlateNumber || locale.any}
            </label>
          </div>
          <div className={styles.row}>
            <label>{locale.averageFullCreditPrice}</label>
            <label>{formatAmount(transactionDetails?.averageCreditPrice) || locale.any}</label>
          </div>
          <div className={styles.row}>
            <label>{locale.totalCreditAmount}</label>
            <label>{formatAmount(transactionDetails?.totalCreditPrice) || locale.any}</label>
          </div>
          <div className={styles.row}>
            <Field className={styles.fieldContainer} {...fields.orNumber}>
              <TextField
                {...fields.orNumber}
                className={styles.formContainer}
                onChange={modifyField}
              />
            </Field>
          </div>
        </div>

        <Button
          primary
          className={styles.doneButton}
          disabled={!isFormSubmittable}
          loading={loading}
          onClick={() => {
            onSubmitOR(fields.orNumber);
            setDetails(null);
            reset();
          }}
        >
          {locale.done}
        </Button>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
