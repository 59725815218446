import React, { useCallback, useContext, useMemo, useState } from "react";
import locale from "localization";
import { getRedemptions, generateStationReport } from "apis";
import { useApi, useFilter, useModal, useMount, useExport } from "hooks";
import moment from "moment";
import { formatAmount } from "utils/text.utils";
import { formatVolume } from "utils";
import {
  prettifyProduct,
  prettifyRedemptionStatus,
  prettifySettlementStatus,
  prettifyVoidTransactionValue,
} from "utils/pretty.utils";
import { DateTime, Products, SettlementStatus } from "enums";
import styles from "./payment-history-business.module.scss";
import { Product, Text, Pill, PopOverMenu, Intro, DataTable, PopOver } from "components/commons";
import RedemptionStatus from "enums/redemption-status";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { paymentHistoryState } from "../payment-history/payment-history-filter.state";
import { StationContext } from "contexts";
import ConfirmModal from "components/modals/confirm-modal/confirm-modal";
import SuccessModal from "components/modals/success-modal/success-modal";
import VoidTransactionModal from "./void-transaction-modal";
import ReceiptGuideModal from "./receipt-guide-modal";
import PaymentHistoryBusinessFilter from "./payment-history-business-filter";
import { updateRedemptionRemarks, voidRedemption } from "apis/redemption.api";

const PaymentHistoryForBusiness = () => {
  const [remarks, setRemarks] = useState();
  const [value, setValue] = useState("");
  const {
    request: getPaymentHistoryRequest,
    loading: fetchingPaymentHistory,
    result: getPaymentHistoryResult = { redemptions: [], count: 0 },
  } = useApi({
    api: getRedemptions,
    pageError: true,
    params: {
      platformType: "plb",
    },
  });

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidRedemption,
    modalError: true,
    pageError: false,
  });

  const updateRemarksApi = useApi({
    api: updateRedemptionRemarks,
    modalError: true,
    pageError: false,
  });

  const voidTransactionModal = useModal();
  const receiptGuideModal = useModal();
  const confirmModal = useModal();

  const { station } = useContext(StationContext);
  const { stationId } = station;

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    submitFilter,
    submittedFilter,
    clearFilter,
    filterCount,
  } = useFilter(paymentHistoryState(stationId));

  useMount(() => {
    fetchPaymentHistory(requestState);
  });

  const paymentHistoryColumns = [
    { key: "redemptionId", text: locale.redemptionId, width: "200px" },
    { key: "redeemedAt", text: locale.redemptionDate, width: "350px" },
    { key: "businessName", text: locale.businessName, width: "400px" },
    { key: "driverId", text: locale.driverId, width: "200px" },
    { key: "plateNumber", text: locale.plateNumber, width: "200px" },
    { key: "fuelCode", text: locale.fuelCodeCapitalize, width: "150px" },
    { key: "product", text: locale.product, width: "220px" },
    { key: "volume", text: locale.volume, width: "220px" },
    { key: "averageCreditPrice", text: locale.averageCreditPrice, width: "300px" },
    { key: "totalCreditAmount", text: locale.totalCreditAmount, width: "300px" },
    { key: "orNumber", text: locale.orNumber, width: "250px" },
    { key: "status", text: locale.status, width: "230px" },
    { key: "remarks", text: locale.remarks, width: "230px" },
    {
      key: "settlementStatus",
      text: `${locale.settlementStatus}/${locale.settlementId}`,
      width: "350px",
    },
    { key: "options", text: locale.actions, width: "100px" },
  ];

  const fetchPaymentHistory = useCallback(
    (rs) => {
      const val = submitFilter(rs);
      getPaymentHistoryRequest({
        ...val,
        productCodes: val.productCodes.join(","),
        redemptionStatuses: val.redemptionStatuses.join(","),
        settlementStatuses: val.settlementStatuses.join(","),
      });
    },
    [getPaymentHistoryRequest, submitFilter]
  );

  const onVoidTransactionCb = useCallback(
    async ({ redemptionItem, value }) => {
      await voidRequest({ redemptionId: redemptionItem.redemptionId, remarks: value });
      voidTransactionModal.close();
      fetchPaymentHistory(requestState);
      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisTransactionHasBeenVoided}
            items={[
              <b>{formatVolume(redemptionItem?.redemption?.literVolume)}</b>,
              <b>{redemptionItem?.fleet?.businessName}</b>,
            ]}
          />
        ),
        loading: voidingRequest,
        redemptionItem,
      });
      setRemarks("");
    },
    [
      voidRequest,
      voidTransactionModal,
      fetchPaymentHistory,
      voidingRequest,
      confirmModal,
      requestState,
    ]
  );

  const data = useMemo(() => {
    const { redemptions = [] } = getPaymentHistoryResult || {};
    if (redemptions && redemptions.length > 0) {
      const preparedData = redemptions.map((d) => {
        const map = new Map();
        map.set("redemptionId", <div className="min-60 nowrap">{d.redemptionId}</div>);
        map.set("fuelCode", d.fuelCode);
        map.set(
          "redeemedAt",
          <div className="min-80">
            <Text>{moment(d.redeemedAt).format(DateTime.A)}</Text>
            <Text>{moment(d.redeemedAt).format(DateTime.B)}</Text>
          </div>
        );
        map.set(
          "product",
          <Product
            grass={d.productCode === Products.Gas91}
            salsa={d.productCode === Products.Gas95}
            deepBlue={d.productCode === Products.Gas97}
            cheddar={d.productCode === Products.Diesel}
          >
            {prettifyProduct(d.productCode)}
          </Product>
        );
        map.set("businessName", <div className="min-80">{d.fleet.businessName || ""}</div>);
        map.set("plateNumber", d.vehicle?.plateNumber || <Text italic>{locale.any}</Text>);
        map.set(
          "driverId",
          <div className="min-50">
            {d.driver?.driverLicenseId || <Text italic>{locale.any}</Text>}
          </div>
        );
        map.set("volume", <div className="min-50 nowrap">{formatVolume(d.literVolume)}</div>);
        map.set(
          "averageCreditPrice",
          <div className="min-50 nowrap">{formatAmount(d.averageCreditPrice)}</div>
        );
        map.set(
          "totalCreditAmount",
          <div className="min-50 nowrap">{formatAmount(d.totalCreditPrice)}</div>
        );
        map.set(
          "orNumber",
          <div>
            <Text className={styles.overflow}>{d.orNumber}</Text>
          </div>
        );
        map.set(
          "status",
          <Pill
            grass={d.redemption?.status === RedemptionStatus.Success}
            cement={d.redemption?.status === RedemptionStatus.Voided}
          >
            {prettifyRedemptionStatus(d.redemption?.status)}
          </Pill>
        );

        map.set(
          "remarks",
          d.redemption?.remarks ? (
            <PopOver content={<div className={styles.remarks}>{d.redemption?.remarks}</div>}>
              <div className="link">View</div>
            </PopOver>
          ) : (
            ""
          )
        );
        map.set(
          "settlementStatus",
          <>
            <Pill
              grass={d.redemption.settlementStatus === SettlementStatus.Processed}
              cyan={d.redemption.settlementStatus === SettlementStatus.ForSettlement}
              sky={d.redemption.settlementStatus === SettlementStatus.ForProcessing}
            >
              {prettifySettlementStatus(d.redemption.settlementStatus)}
            </Pill>
            {d.redemption.settlementStatus === SettlementStatus.ForProcessing ||
            d.redemption.settlementStatus === SettlementStatus.Processed ? (
              <Text className={styles.subBusinessId}>{d.redemption.settlementId}</Text>
            ) : (
              <Text className={styles.subBusinessId}>--</Text>
            )}
          </>
        );
        const options = [];
        if (
          d.redemption.status === RedemptionStatus.Success &&
          moment().format("MM YYYY") === moment(d.redemption.redeemedAt).format("MM YYYY")
        ) {
          options.push({
            content: locale.voidTransaction,
            onClick: () => {
              setRemarks("");
              voidTransactionModal.show({
                redemptionItem: d,
                title: `${locale.voidTransaction}?`,
                description: (
                  <Text align="center">
                    <locale.Populate
                      text={
                        d.redemption?.settlementId
                          ? d.redemption?.settlementStatus === SettlementStatus.ForProcessing
                            ? locale.voidingThisTransactionSettlementProcessing
                            : locale.voidingThisTransactionSettlementProcessed
                          : locale.voidingThisTransaction
                      }
                      items={[<b>{formatVolume(d?.redemption?.literVolume)}</b>]}
                    />
                  </Text>
                ),
                label: `${locale.reason}/${locale.remarks}`,
                actionText: locale.voidTransaction,
                submit: (value) => {
                  onVoidTransactionCb({
                    redemptionItem: d,
                    value,
                  });
                },
              });
            },
          });
        }
        options.push({
          content: locale.viewReceiptGuide,
          onClick: () => {
            setRemarks("");
            receiptGuideModal.show({
              redemptionItem: d,
              title: `${locale.receiptGuide}`,
              description: "",
              label: `${locale.reason}/${locale.remarks}`,
            });
          },
        });
        if (d.redemption.status === RedemptionStatus.Voided) {
          options.push({
            content: locale.editRemarks,
            onClick: () => {
              setRemarks(d.redemption?.remarks);
              setValue(prettifyVoidTransactionValue(d.redemption?.remarks));
              voidTransactionModal.show({
                redemptionItem: d,
                title: locale.editRemarks,
                description: null,
                label: `${locale.reason}/${locale.remarks}`,
                actionText: locale.save,
                submit: async (value) => {
                  await updateRemarksApi.request({
                    id: d.redemptionId,
                    remarks: value,
                  });
                  voidTransactionModal.close();
                  setRemarks("");
                  fetchPaymentHistory(requestState);
                },
              });
            },
          });
        }

        if (options.length) {
          map.set(
            "options",
            <PopOverMenu options={options}>
              <MoreVertIcon className={styles.icon} />
            </PopOverMenu>
          );
        } else {
          map.set(
            "options",

            <MoreVertIcon className={styles.iconDisabled} />
          );
        }
        return map;
      });
      return preparedData;
    }
    return [];
  }, [
    voidTransactionModal,
    receiptGuideModal,
    getPaymentHistoryResult,
    updateRemarksApi,
    fetchPaymentHistory,
    onVoidTransactionCb,
    requestState,
  ]);

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate, page: 1 });
    },
    [modifyFilters]
  );

  const onProductChangeCb = useCallback(
    (productCodes) => {
      modifyFilters({ productCodes, page: 1 });
    },
    [modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1, perPage: 10 });
      fetchPaymentHistory(requestState);
    },
    [modifyFilters, fetchPaymentHistory]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchPaymentHistory(requestState);
    },
    [modifyFilters, fetchPaymentHistory]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchPaymentHistory(requestState);
    },
    [modifyFilters, fetchPaymentHistory]
  );

  const onRedemptionStatusChangeCb = useCallback(
    (name, { value }) => {
      modifyFilters({ redemptionStatuses: value });
      // fetchPaymentHistory(requestState);
    },
    [modifyFilters]
  );

  const exportReport = useExport({
    api: generateStationReport,
    reportType: "plb-redemption",
    hasModal: true,
    mappedFilterState: {
      ...requestState,
      platformType: "plb",
      redemptionStationId: stationId,
      redemptionStatuses: requestState?.redemptionStatuses.join(","),
      settlementStatuses: requestState?.settlementStatuses.join(","),
      productCodes: requestState?.productCodes.join(","),
    },
  });

  return (
    // <PaymentHistory
    //   title={locale.business}
    //   requestApi={requestApi}
    //   columns={paymentHistoryColumns}
    //   data={data}
    //   placeholder={locale.searchIdBusinessPlaceOrFuelCode}
    // />
    <div>
      <VoidTransactionModal
        {...voidTransactionModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={voidingRequest || updateRemarksApi.loading}
        onSubmit={voidTransactionModal.submit}
        value={value}
        setValue={setValue}
      />

      <ReceiptGuideModal
        {...receiptGuideModal}
        remarks={remarks}
        value={value}
        setValue={setValue}
      />
      <SuccessModal {...exportReport?.successModalComponent} />
      <ConfirmModal {...confirmModal} />
      <div>
        <Intro title={locale.populate(locale.paymentHistoryTitle, [locale.business])} />
      </div>
      <div className={styles.filters}>
        <PaymentHistoryBusinessFilter
          filterCount={filterCount}
          placeholder={locale.searchIdBusinessPlaceOrFuelCodeOrRedemptionId}
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onProductChange={onProductChangeCb}
          onRedemptionStatusChangeCb={onRedemptionStatusChangeCb}
          onSearchChange={(name, { value }) => {
            modifyFilter(name, { value });
          }}
          onSearch={onSearchCb}
          modifyFilters={modifyFilters}
          resetFilter={() => {
            const val = {
              productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
              endDate: null,
              startDate: null,
              ...submittedFilter,
            };
            modifyFilters(val);
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchPaymentHistory(requestState);
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchPaymentHistory(requestState);
          }}
          {...exportReport}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingPaymentHistory}
          page={filterState.page}
          perPage={filterState.perPage}
          columns={paymentHistoryColumns}
          data={data}
          dataCount={getPaymentHistoryResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
    </div>
  );
};

export default PaymentHistoryForBusiness;
