import { StationContext } from "contexts";
import { Path, StationStatus } from "enums";
import PayWithPesoModule from "modules/payment/pay-with-peso/pay-with-peso.module";
import PaymentHistoryForBusiness from "modules/payment/payment-history-business/payment-history-business.module";
import PaymentHistoryForConsumer from "modules/payment/payment-history-consumer/payment-history-consumer.module";
import NonFuelModule from "modules/payment/non-fuel/non-fuel.module";
import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

const PaymentHistoryPage = () => {
  const { station } = useContext(StationContext);
  const { isPlbOnboarded, status } = station;
  return (
    <Switch>
      {isPlbOnboarded && (
        <Route exact path={Path.PaymentHistoryBusiness} component={PaymentHistoryForBusiness} />
      )}
      {status === StationStatus.Active && (
        <Route exact path={Path.PaymentHistoryConsumer} component={PaymentHistoryForConsumer} />
      )}
      {status === StationStatus.Active && (
        <Route exact path={Path.PayWithPeso} component={PayWithPesoModule} />
      )}

      {status === StationStatus.Active && (
        <Route exact path={Path.NonFuel} component={NonFuelModule} />
      )}
      <Redirect to={isPlbOnboarded ? Path.PaymentHistoryBusiness : Path.PaymentHistoryConsumer} />
    </Switch>
  );
};

export default PaymentHistoryPage;
