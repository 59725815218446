import { Products } from "enums";
import RedemptionStatus from "enums/redemption-status";
import { SettlementStatus } from "enums";

export const paymentHistoryState = (stationId = 0) => ({
  stationId,
  startDate: null,
  endDate: null,
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
  page: 1,
  perPage: 10,
  redemptionStatuses: [RedemptionStatus.Success, RedemptionStatus.Voided],
  settlementStatuses: [
    SettlementStatus.ForSettlement,
    SettlementStatus.ForProcessing,
    SettlementStatus.Processed,
  ]
});
