import { useEffect, useMemo, useState } from "react";

const useForm = ({ initialState = {}, validation = null }) => {
  const [fields, setFields] = useState({ ...initialState });
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    validateFields(initialState);
    setFields(setDefaultFields({ ...initialState }));
    checkIfFormHasError(initialState, () => {
      setError(true);
    });
  }, [initialState]);

  const isFormSubmittable = useMemo(() => {
    let isRequiredFieldsFilled = true;
    for (const [, formValue] of Object.entries(fields)) {
      let notFilled = formValue.required && !formValue.value;
      if (formValue.type === "date" && formValue.required) {
        notFilled = !formValue.value.startDate || !formValue.value.endDate;
      }
      if (notFilled && !formValue.disabled) {
        isRequiredFieldsFilled = false;
      }
    }
    let hasError = false;
    for (const [, formValue] of Object.entries(fields)) {
      if (formValue.error) {
        hasError = true;
      } else if (formValue.isFormArray) {
        hasError = formValue.value.some((fv) => {
          return fv.error;
        });
      }
    }

    return dirty && isRequiredFieldsFilled && !hasError;
  }, [dirty, fields]);

  const modifyForm = (fs) => {
    setDirty(true);

    const mergedFields = setDefaultFields(mergeFields(fs, fields));

    const newFields = runFieldValidations(mergedFields);
    setFields(newFields);

    // Error checking below
    const hasError = checkIfFormHasError(newFields, () => {
      setError(true);
    });

    if (hasError) return { fields: newFields, dirty, error: true };

    setError(false);
    // Validate based on custom validation
    if (validation) {
      const { error } = validation(fields);
      if (error) {
        setError(true);
        return { fields: newFields, dirty, error };
      }
    }

    return { fields: newFields, dirty, error: false };
  };

  const modifyField = (name, obj) => {
    return modifyForm({ [name]: obj }, obj.dirty);
  };

  const clearForm = () => {
    setDirty(false);
    setError(false);
    setFields(initialState);

    return { fields: initialState, dirty, error };
  };

  const validateField = (name, { value }) => {
    modifyField(name, { value });
  };

  const validateForm = () => {
    const dirtyFields = makeFormDirty(fields, () => {
      setDirty(true);
    });
    const validatedFields = runFieldValidations(dirtyFields);

    setFields(validatedFields);

    const hasError = checkIfFormHasError(validatedFields, () => {
      setError(true);
    });

    // Validate based on custom validation
    if (validation) {
      const { error } = validation(fields);
      if (error) {
        setError(true);
        return { fields, dirty, error };
      }
    }

    if (hasError) return { fields: validatedFields, dirty: true, error: true };

    setError(false);
    return { fields: validatedFields, dirty: true, error: false };
  };

  const submitForm = async (callback) => {
    let response = null;
    const { fields, error } = validateForm();
    if (callback && !error) {
      try {
        response = await callback(fields, error);
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
    return { fields, error, response };
  };

  const applyFieldErrors = (fieldErrors, currentField = {}) => {
    let newFields = { ...fields };

    for (const [k, fieldError] of Object.entries(fieldErrors)) {
      // if (typeof fieldError !== "string") {
      //   console.warn(`Error message must be string.`);
      //   continue;
      // }

      if (!fields.hasOwnProperty(k)) {
        console.warn(`Property ${k} is not existing on form. Will disregard.`);
        continue;
      }

      newFields = { ...newFields, [k]: { ...newFields[k], error: true, message: fieldError } };

      if (currentField[k]) {
        newFields[k] = {
          ...newFields[k],
          ...currentField[k],
        };
      }
    }
    setFields(newFields);
    setError(true);
    return { fields: newFields };
  };

  const getFormValues = () => {
    let formValues = {};
    for (const [k, formValue] of Object.entries(fields)) {
      formValues[k] = formValue.value;
    }
    return formValues;
  };

  const makeFormInvalid = () => {
    setError(true);
  };

  return {
    fields,
    dirty,
    error,
    modifyForm,
    clearForm,
    modifyField,
    validateForm,
    submitForm,
    applyFieldErrors,
    getFormValues,
    makeFormInvalid,
    isFormSubmittable,
    validateField,
  };
};

const validateFields = (fields) => {
  if (Object.keys(fields) <= 0) {
    throw new Error("Fields must have atleast one property");
  }
  return fields;
};

const setDefaultFields = (fields) => {
  let fieldsWithDefault = {};

  for (let [k, field] of Object.entries(fields)) {
    let fieldWithDefault = {
      ...field,
      error: field.error || false,
      message: field.message || "",
      dirty: field.dirty || false,
      validations: field.validations || [],
      value: getDefaultValue(field.value),
    };

    fieldsWithDefault = { ...fieldsWithDefault, [k]: fieldWithDefault };
  }

  return { ...fieldsWithDefault };
};

const getDefaultValue = (value) => {
  if (value) {
    return value;
  } else {
    if (typeof value === "string") {
      return "";
    }
    if (typeof value === "boolean") {
      return false;
    }
    if (Array.isArray(value)) {
      return [];
    }
    return null;
  }
};

const mergeFields = (newFields, currentFields) => {
  let newlyMergedFields = {};

  for (const [k, newField] of Object.entries(newFields)) {
    if (currentFields.hasOwnProperty(k)) {
      newlyMergedFields[k] = { ...currentFields[k], ...newField, dirty: true };
    }
  }
  return { ...currentFields, ...newlyMergedFields };
};

const validateField = (field, parent) => {
  const { disabled } = field || {};

  if (disabled) {
    return { ...field, error: false, message: "" };
  }

  if (field.validations?.length > 0) {
    for (const validation of field.validations) {
      const { error, message, ...additionalProperties } = validation(field, parent);

      if (error || message) {
        return { ...field, error, message, ...additionalProperties };
      }
    }
  }
  return { ...field, error: false, message: "" };
};

const checkIfFormHasError = (fields, callback) => {
  let hasError = false;
  for (const [, field] of Object.entries(fields)) {
    if (field.error) {
      callback();
      hasError = true;
      break;
    } else if (field.isFormArray) {
      hasError = field.value.some((fv) => {
        return fv.error;
      });
    }
  }
  return hasError;
};

const runFieldValidations = (fields) => {
  let newFields = {};

  if (validateFields(fields)) {
    for (const [k, field] of Object.entries(fields)) {
      if (field.dirty) {
        newFields = {
          ...newFields,
          [k]: validateField(field, fields),
        };
      } else {
        newFields = { ...newFields, [k]: field };
      }
    }
  }
  return newFields;
};

const makeFormDirty = (fields, callback) => {
  let dirtyFields = { ...fields };

  for (const [, df] of Object.entries(dirtyFields)) {
    if (!df.dirty) {
      df.dirty = true;
    }
  }

  callback();

  return dirtyFields;
};

export default useForm;
