const ErrorCode = {
  IncorrectCredentials: "P1002",
  DeactivatedFuelCode: "FC1001",
  NotYetValidFuelCode: "FC1002",
  ExpiredFuelCode: "FC1003",
  ReachedLimitFuelCode: "FC1004",
  InvalidFuelCode: "FC1005",
  FuelCodeNotEnoughBalance: "FC1007",
  InsufficientBalance: "W1001",
  NoProductFuelBalance: "FB1002",
  NotActiveDriver: "FC1011",
  NotActiveVehicle: "FC1012",
  InvalidInviteStationToken: "S1001",
  ExpiredInviteStationToken: "S1003",
  InactiveFleet: "F1001",
  UserNotFound: "P1005",
  InvalidForgotPasswordToken: "P1009",
  ExpiredForgotPasswordToken: "P1010",
  UserInviteStillPending: "U1002",
  UserDontBelongToAStation: "U1001",
  FuelCodeDoesNotBelongToAStation: "FC1013",
};

export default ErrorCode;
