import locale from "localization";

export const payWithPesoColumns = [
  {
    key: "paymentId",
    text: locale.payWithPesoId,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "createdAt",
    text: locale.dateOfTransaction,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "product",
    text: locale.product,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "paymentMethod",
    text: locale.paymentMethod,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "amount",
    text: locale.paymentAmount,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "status",
    text: `${locale.status}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "settlementStatus",
    text: `${locale.settlementStatus} / ${locale.settlementId}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "action",
    text: `${locale.action}`,
    style: {
      minWidth: "120px",
    },
  },
];
