import { SettlementStatus } from "enums";
import PayWithPesoStatus from "enums/pay-with-peso-status";

export const nonFuelFilterState = () => ({
  startDate: null,
  endDate: null,
  searchKey: "",
  settlementStatuses: [
    SettlementStatus.ForProcessing,
    SettlementStatus.ForSettlement,
    SettlementStatus.Processed,
  ],
  status: [PayWithPesoStatus.Success, PayWithPesoStatus.Voided],
  page: 1,
  perPage: 10,
});
