import moment from "moment";
import { Text } from "components/commons";
import { prettifyLubeServSite, prettifyOilChangeType } from "utils/pretty.utils";

import { DateTime } from "enums";
import LabelAndValue from "components/commons/label-and-value/label-and-value";

export const mapDataToList = (data) => {
  const {
    bookingId,
    createdAt,
    firstName,
    lastName,
    mobileNumber,
    email,
    vehicleModel,
    vehicleYear,
    oilChangePackage,
    address,
    preferredDate,
    lubeServSite,
  } = data;

  return {
    bookingId: <div>{bookingId}</div>,
    createdAt: <div>{moment(createdAt).format(DateTime.G)}</div>,
    name: (
      <LabelAndValue label={mobileNumber}>
        {`${firstName} ${lastName}`.trim() || email}
      </LabelAndValue>
    ),
    email: email || "-",
    type: prettifyOilChangeType(oilChangePackage) || "-",
    vehicleInfo: (
      <LabelAndValue label={vehicleYear}>{`${vehicleModel}`.trim() || "-"}</LabelAndValue>
    ),
    address: address,
    lubeServSite: prettifyLubeServSite(lubeServSite),
    preferredDateTime: (
      <>
        <Text
          subtitle
          style={{
            marginTop: "10px",
          }}
        >
          {moment(preferredDate).format(DateTime.G)}
        </Text>
      </>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { type, selectedPreferredDate, selectedPreferredTime, ...fs } = filterState;
  const formattedDate =
    selectedPreferredDate != null ? moment(selectedPreferredDate).format("YYYY-MM-DD") : "";

  return {
    ...fs,
    oilChangePackages: type.join(","),
    preferredDate: formattedDate,
    preferredTime: selectedPreferredTime ? selectedPreferredTime.join(",") : null,
  };
};
