import {
  prettifyPayWithPesoStatus,
  prettifySettlementStatus,
} from "utils/pretty.utils";
import { formatAmount, formatDate } from "utils";
import { DateTime, SettlementStatus } from "enums";
import PayWithPesoStatus from "enums/pay-with-peso-status";
import LabelAndPill from "components/commons/label-and-pill/label-and-pill";
import { locale } from "localization/en";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PopOverMenu } from "components/commons";

import styles from "./non-fuel.module.scss";

export const mapDataToList = ({ merchantPayments, handleVoidPayment, handleActionsHistory }) => {
  const {
    merchantPaymentId,
    paidAt,
    amount,
    status = PayWithPesoStatus.Success,
    settlementStatus,
    settlementId,
    voidedAt,
    voidedByEmail,
    merchant
  } = merchantPayments;

  const { businessName } = merchant;

  const options = [];

  if (status === PayWithPesoStatus.Success) {
    options.push({
      content: locale.voidTransaction,
      onClick: () =>
        handleVoidPayment({
          merchantPaymentId,
          amount,
          settlementStatus,
          settlementId,
        }),
    });
  } else if (status === PayWithPesoStatus.Voided) {
    options.push({
      content: locale.actionsHistory,
      onClick: () => handleActionsHistory({ voidedByEmail, status, voidedAt }),
    });
  }

  return {
    paymentId: <div>{merchantPaymentId}</div>,
    createdAt: formatDate(paidAt, DateTime.J),
    amount: formatAmount(amount),
    status: (
      <LabelAndPill
        label={voidedAt ? formatDate(voidedAt, DateTime.G) : ""}
        pillProps={{
          sky: status === PayWithPesoStatus.Pending,
          deepRed: status === PayWithPesoStatus.Failed,
          grass: status === PayWithPesoStatus.Success,
          cement: status === PayWithPesoStatus.Voided,
        }}
        pillLabel={prettifyPayWithPesoStatus(status)}
      />
    ),
    settlementStatus: (
      <div>
        <LabelAndPill
          label={
            settlementStatus === SettlementStatus.ForProcessing ||
            settlementStatus === SettlementStatus.Processed
              ? SettlementStatus.Processed
              : ""
          }
          pillProps={{
            cyan: settlementStatus === SettlementStatus.ForSettlement,
            sky: settlementStatus === SettlementStatus.ForProcessing,
            grass: settlementStatus === SettlementStatus.Processed,
          }}
          pillLabel={prettifySettlementStatus(settlementStatus)}
        />
      </div>
    ),
    settlementId: settlementId ? settlementId : "- -",
    settlementPayee: businessName,
    action: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { settlementStatuses, status, ...fs } = filterState;

  return {
    ...fs,
    settlementStatuses: settlementStatuses.join(","),
    status: status.join(","),
  };
};
