import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import SettlementHistoryModule from "modules/settlement/settlement-history/settlement-history.module";
import ViewSettlementHistory from "modules/settlement/settlement-history/view-settlement-history/view-settlement-history.module";

const SettlementHistoryPage = () => {
  return (
    <Switch>
      <Route exact path={Path.SettlementHistory} component={SettlementHistoryModule} />
      <Route path={Path.ViewSettlementHistory} component={ViewSettlementHistory} />
      <Redirect to={Path.ViewSettlementHistory} />
    </Switch>
  );
};

export default SettlementHistoryPage;