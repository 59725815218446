import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Button, 
  Pill, 
  PopOverList, 
  PopOverMenu, 
  Text 
} from "components/commons";
import locale  from "localization";
import { dateTimeFormat, formatAmount, formatVolume, prettifySettlementStatus } from "utils";
import { SettlementStatus, Path } from "enums";

import styles from "./settlement-history.module.scss"

export const mapDataToList = ({ settlements, history }) => {
  const {
    createdAt,
    settlementId,
    station,
    totalLiter,
    totalDealerSettlement,
    dealerSettlementRemarks,
    dealerSettlementStatus,
    dealerSettlementUpdatedAt,
    totalMdrFee,
    totalPayWithPeso,
  } = settlements

  return {
    dateProcessed: <>
      <Text>{dateTimeFormat(createdAt)}</Text>
      <Text className={styles.subBusinessId}>{settlementId}</Text>
    </>,
    redemptionStation: <>
      <Text>{station?.name}</Text>
      <Text className={styles.subBusinessId}>{station?.businessName}</Text>
    </>,
    totalLiters: formatVolume(totalLiter),
    totalPayPeso: formatAmount(totalPayWithPeso),
    totalMDRFee: formatAmount(totalMdrFee),
    totalDealerPayout: formatAmount(totalDealerSettlement),
    dealerPayoutStatus: <>
      <Pill
        sky={
          dealerSettlementStatus === SettlementStatus.ForProcessing ||
          dealerSettlementStatus === SettlementStatus.ForSettlement 
        }
        grass={
          dealerSettlementStatus === SettlementStatus.Processed || 
          dealerSettlementStatus === SettlementStatus.Settled
        }
      >
        {prettifySettlementStatus(dealerSettlementStatus)}
      </Pill>
      <Text className={styles.subBusinessId}>{dealerSettlementUpdatedAt ? dateTimeFormat(dealerSettlementUpdatedAt) : "--"}</Text>
    </>,
    dealerRemarks: dealerSettlementStatus === SettlementStatus.Processed && dealerSettlementRemarks ? (
      <PopOverList list={[dealerSettlementRemarks]}>
        <Button link>{locale.view}</Button>
      </PopOverList>
    ) : (
      "--"
    ),
    action: <PopOverMenu
      options={[
        {
          content: locale.viewDetails,
          onClick: () => history.push(Path.ViewSettlementHistoryById(settlementId))
        } 
      ]}
    >
      <MoreVertIcon className={styles.icon} />
    </PopOverMenu>
  }
}

export const mapFilterToRequest = (filterState) => {
  const { discountVoucherStatuses, dealerSettlementStatuses, ...fs } = filterState
  return {
    ...fs,
    discountVoucherStatuses: discountVoucherStatuses.join(","),
    dealerSettlementStatuses: dealerSettlementStatuses.join(",")
  }
}