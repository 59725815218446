import { SettlementStatus } from "enums"

export const settlementHistoryFilterState = () => ({
  searchKey: "",
  startDate: null,
  endDate: null,
  discountVoucherStatuses: [
    SettlementStatus.ForProcessing, 
    SettlementStatus.Processed,
  ],
  dealerSettlementStatuses: [
    SettlementStatus.ForProcessing, 
    SettlementStatus.Processed,
  ],
  perPage: 10,
  page: 1
})