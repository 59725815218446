import { GuyWithMagGlass } from "images";
import locale from "localization";
import React, { useCallback } from "react";
import Image from "../image/image";
import Loader from "../loader/loader";
import Pagination from "../pagination/pagination";
import Table from "../table/table";
import Text from "../text/text";
import Title from "../title/title";
import styles from "./data-table.module.scss";
import classNames from "classnames";
import { useMemo } from "react";
import NextPrevPagination from "../pagination/next-prev-pagination";

const DataTableV2 = ({
  loading,
  pageState,
  applyPage,
  data,
  dataKey,
  columns,
  result,
  hidePagination = false,
  actionHeader = false,
  actionHeaderProps,
  onCheckAll,
  hasColumnPicker,
  ...props
}) => {
  const { page, perPage } = pageState;

  const onChangePageCb = useCallback(
    (page) => {
      if (applyPage) {
        return applyPage({ page });
      }
    },
    [applyPage]
  );

  const onChangePageSizeCb = useCallback(
    (pageSize) => {
      if (applyPage) {
        return applyPage({ perPage: pageSize, page: 1 });
      }
    },
    [applyPage]
  );

  const onCheckAllCb = useCallback(
    (checked) => {
      if (onCheckAll) {
        return onCheckAll(checked);
      }
    },
    [onCheckAll]
  );
  const resultData = useMemo(() => {
    if (props.isReverse) {
      const d = [...data];
      const newDataSet = [];
      d.forEach((_, id) => {
        const item = d[d.length - (id + 1)];
        const map = new Map();
        columns.forEach(({ key }) => {
          const val = item.get(key);
          map.set(key, val);
        });
        map.set("className", item.get("className"));
        newDataSet.push(map);
      });
      return newDataSet;
    }
    return [...data];
  }, [props, data, columns]);

  const render = useMemo(() => {
    if (loading) {
      return <Loader open={loading} />;
    }

    if (!resultData.length) {
      return (
        <div className={styles.noSearchResultRender}>
          <div>
            <Image src={GuyWithMagGlass} />
            <Title small>{locale.sorryNoResultFound}</Title>
            <Text subtitle>{locale.weCouldNotFindAMatchForSearchCriteria}</Text>
            <Text subtitle>{locale.pleaseTryADifferentOne}</Text>
          </div>
        </div>
      );
    }
    return (
      <>
        <Table
          columns={columns}
          actionHeaderProps={actionHeaderProps}
          actionHeader={actionHeader}
          onCheckAll={onCheckAllCb}
          hasColumnPicker={hasColumnPicker}
          data={resultData}
          isRowCard={props.isRowCard}
          {...props}
        />
      </>
    );
  }, [
    loading,
    actionHeader,
    actionHeaderProps,
    columns,
    hasColumnPicker,
    onCheckAllCb,
    props,
    resultData,
  ]);

  return (
    <div>
      {render}
      <div
        className={classNames({
          [`${styles.hidePagination}`]: (!data.length && !loading) || hidePagination,
        })}
      >
        {result.count ? (
          <Pagination
            page={page}
            pageSize={perPage}
            className={styles.pagination}
            dataCount={result.count}
            onChangePage={onChangePageCb}
            onChangePageSize={onChangePageSizeCb}
          />
        ) : (
          <div
            style={{
              width: "100%",
              justifyContent: "flex-end",
            }}
            className="flex"
          >
            <NextPrevPagination
              {...props}
              page={page}
              currentItemCount={data.length}
              perPage={perPage}
              className={styles.pagination}
              onChange={(val) => {
                applyPage(val);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DataTableV2;
