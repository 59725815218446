import React, { useState } from "react";
import { useForm, useApi } from "hooks";
import { initialState } from "./login.state";
import styles from "./login.module.scss";
import { login, getUserV2 } from "apis";
import { ErrorCode, Path, Portal } from "enums";
import { Image, Footer, Title } from "components/commons";
import classNames from "classnames";
import { PriceLOCQLogo } from "images";
import locale from "localization";
import { handleRequest } from "utils";
import { GirlGasCar } from "images";
import * as queryString from "query-string";
import { useHistory, Route, Switch } from "react-router-dom";
import env from "environments/env";
import LoginForm from "./login-form";
import ForgotPasswordModule from "../forgot-password/forgot-password.module";
import ResetPasswordModule from "../reset-password/reset-password.module";

const LoginModule = (props) => {
  const testTitle = env.ID !== "prod" ? `(${locale.testingMode})` : "";
  const [incorrectCreds, setIncorrectCreds] = useState();
  const parse = queryString.parse(props.location ? props.location.search : null);
  const [errorCode] = useState(parse.code || null);
  const history = useHistory();

  const { request: loginRequest, loading: loggingIn } = useApi({
    api: login,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: getUserRequest } = useApi({
    api: getUserV2,
    pageError: true,
  });

  const {
    fields,
    modifyField,
    submitForm,
    makeFormInvalid,
    getFormValues,
    applyFieldErrors,
    isFormSubmittable,
  } = useForm({
    initialState,
  });

  const handleLogin = async () => {
    setIncorrectCreds(false);
    const { email, password, remember } = getFormValues();
    if (!email || !password) {
      applyFieldErrors({
        email: !email ? "" : null,
        password: !password ? "" : null,
      });
      return;
    }

    handleRequest(
      async () => {
        const res = await loginRequest({ email, password }, () => handleLogin());
        localStorage.setItem("accessToken", res?.AccessToken);
        localStorage.setItem("refreshToken", res.RefreshToken);

        const userDetails = await getUserRequest();

        if (userDetails) {
          const hasUserAccess = userDetails?.userAccesses;
          if (hasUserAccess.length > 0) {
            const { role } = userDetails?.userAccesses?.find((element) => {
              return element.portal === Portal.STATION;
            });
            localStorage.setItem("role", role);

            if (remember) {
              localStorage.setItem("email", email);
            } else {
              localStorage.removeItem("email");
            }

            history.push(Path.SelectStation);
          } else {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");

            makeFormInvalid();
            setIncorrectCreds(true);
            return applyFieldErrors({
              email: "",
              password: "",
            });
          }
        }
      },
      null,
      (err) => {
        if (err.data?.errorCode === ErrorCode.IncorrectCredentials) {
          makeFormInvalid();
          setIncorrectCreds(true);
          return applyFieldErrors({
            email: "",
            password: "",
          });
        }
        return err.showError();
      }
    );
  };

  const state = {
    incorrectCreds,
    errorCode,
    loggingIn,
    fields,
    modifyField,
    submitForm,
    handleLogin,
    history,
    isFormSubmittable,
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.header)}>
        <Image src={PriceLOCQLogo} className={styles.logoImage} />
      </div>

      <div className={styles.content}>
        <div className={styles.contentContainer}>
          <div className={styles.image}>
            <Image src={GirlGasCar} className={styles.contentImage} />
          </div>
          <div className={styles.login}>
            <Title className={styles.title}>{`${locale.stationPortal} ${testTitle}`}</Title>
            <Switch>
              <Route exact path={Path.Auth} render={() => <LoginForm {...state} />} />
              <Route exact path={Path.ForgotPassword} render={() => <ForgotPasswordModule />} />
              <Route exact path={Path.ResetPassword} render={() => <ResetPasswordModule />} />
            </Switch>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginModule;
