import { Field, TextField, Title, PopOverList, Text } from "components/commons";
import React from "react";
import locale from "localization";
import { initialState } from "./validate-payment.state";
import styles from "./validate-payment.module.scss";
import { useApi, useForm } from "hooks";
import { validateFuelCode, getFuelCode } from "apis";
import { useState } from "react";
import { handleRequest } from "utils";
import { StationContext } from "contexts";
import { useContext } from "react";
import { CircularProgress } from "@material-ui/core";
import { ErrorCode } from "enums";
import ValidatePaymentDetails from "./validate-payment-details";
import { useCallback } from "react";
import Big from "big.js";
import { AnnounceVoidTransaction } from "components/modals";
import { Stepper } from "react-form-stepper";

const ValidatePaymentModule = () => {
  const { station } = useContext(StationContext);
  const { stationCode } = station;

  const [details, setDetails] = useState(null);
  const [, setPaymentDetails] = useState(false);
  const [showActiveStep, setActiveStep] = useState(0);
  const { fields, modifyField, applyFieldErrors, clearForm } = useForm({
    initialState,
  });

  const { request: requestValidateFuelCode, loading: validatingFuelCode } = useApi({
    api: validateFuelCode,
    params: {
      stationCode,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: requestFuelCode, loading: fetchingFuelCode } = useApi({
    api: getFuelCode,
    handleOwnError: {
      badrequest: true,
    },
  });

  const fuelCodeStations = (data) => {
    let stationNames = [];
    data &&
      data.forEach((s) => {
        stationNames.push(s.name);
      });
    return stationNames;
  };

  const onChangeFuelCodeCb = useCallback(
    (name, value) => {
      modifyField(name, value);

      const fuelCode = value.value;

      const applyFuelCodeError = (message) => {
        applyFieldErrors(
          {
            fuelCode: message,
          },
          {
            fuelCode: {
              value: fuelCode,
            },
          }
        );
      };
      if (fuelCode && fuelCode.length === 10) {
        handleRequest(
          async () => {
            const res = await requestValidateFuelCode({
              fuelCode,
            });

            if (res) {
              let driverName = "";
              let driverLicenseId = "";
              let vehicleModel = "";
              let vehiclePlateNumber = "";
              let remarks = "";
              let driverId = "";
              let vehicleId = 0;

              if (res.drivers && res.drivers.length > 0) {
                driverName = `${res.drivers[0].firstName} ${res.drivers[0].lastName}`;
                driverLicenseId = res.drivers[0].driverLicenseId;
                driverId = res.drivers[0].driverId;
              }

              if (res.vehicles && res.vehicles.length > 0) {
                vehicleModel = res.vehicles[0].model;
                vehiclePlateNumber = res.vehicles[0].plateNumber;
                remarks = res.vehicles[0].remarks;
                vehicleId = res.vehicles[0].vehicleId;
              }

              setDetails({
                ...res,
                stationCode,
                driverName,
                driverLicenseId,
                vehicleModel,
                vehiclePlateNumber,
                remarks,
                vehicleId,
                driverId,
                literVolume: Big(res.literVolume).minus(res.volumeRedeemed),
              });

              setPaymentDetails(true);

              setActiveStep(0);
            }
          },
          {
            [ErrorCode.FuelCodeDoesNotBelongToAStation]: async () => {
              const fuelCodeResults = await requestFuelCode({
                fuelCode,
              });
              const { fleet } = fuelCodeResults;
              const stationNames = fuelCodeStations(fleet.fleetStations);

              applyFuelCodeError(
                <locale.Populate
                  text={locale.thisCodeCanOnlyBeRedeemdedAt}
                  items={[
                    <PopOverList
                      className={styles.popOver}
                      listTitle={locale.redemptionStations}
                      list={stationNames}
                    >
                      <span className={styles.stationListLink}>{locale.stations}</span>
                    </PopOverList>,
                  ]}
                />
              );
            },
          },
          async (err) => {
            setDetails(null);
            setPaymentDetails(false);
            switch (err.data?.errorCode) {
              case ErrorCode.DeactivatedFuelCode:
                applyFuelCodeError(locale.deactivatedFuelCode);
                break;
              case ErrorCode.NotYetValidFuelCode:
                applyFuelCodeError(locale.fuelCodeIsInactive);
                break;
              case ErrorCode.ExpiredFuelCode:
                applyFuelCodeError(locale.expiredFuelCode);
                break;
              case ErrorCode.ReachedLimitFuelCode:
                applyFuelCodeError(locale.expiredFuelCode);
                break;
              case ErrorCode.InvalidFuelCode:
                applyFuelCodeError(locale.doesNotExistFuelCode);
                break;
              case ErrorCode.FuelCodeNotEnoughBalance:
                applyFuelCodeError(locale.expiredFuelCode);
                break;
              case ErrorCode.NotActiveDriver:
                applyFuelCodeError(locale.deactivatedFuelCode);
                break;
              case ErrorCode.NotActiveVehicle:
                applyFuelCodeError(locale.deactivatedFuelCode);
                break;
              case ErrorCode.InactiveFleet:
                applyFuelCodeError(locale.deactivatedFuelCode);
                break;
              default:
                return err.showError(true);
            }
          }
        );
      } else {
        setDetails(null);
      }
    },
    [applyFieldErrors, modifyField, requestValidateFuelCode, stationCode, requestFuelCode]
  );

  const activeStepCB = (step) => {
    setActiveStep(step);
  };

  return (
    <div>
      <div className={styles.intro}>
        <Title className={styles.headerLoader}>{locale.payment}</Title>
        <Stepper
          steps={[
            {
              label: locale.validateFuelCodeAndVolumeStep,
            },
            {
              label: locale.actualGasUpDetails,
            },
            { label: locale.payment },
          ]}
          activeStep={showActiveStep}
          connectorStyleConfig={{
            size: 5,
            activeColor: "#3a8dde",
            disabledColor: "#e4e5ec",
            completedColor: "#3a8dde",
          }}
          connectorStateColors={true}
          styleConfig={{
            activeBgColor: "#3a8dde",
            inactiveBgColor: "#e4e5ec",
            completedBgColor: "#3a8dde",
          }}
        />
      </div>
      {!details && (
        <div className={styles.container}>
          <Title className={styles.subtitle}>{locale.validateFuelCodeAndValume}</Title>
          <Text label>{locale.pleaseEnterFuelCode}</Text>
          <Field {...fields.fuelCode}>
            <TextField {...fields.fuelCode} onChange={onChangeFuelCodeCb} />
          </Field>
        </div>
      )}

      {(validatingFuelCode || fetchingFuelCode) && <CircularProgress />}
      {details && (
        <ValidatePaymentDetails
          setPaymentDetails={setPaymentDetails}
          setDetails={setDetails}
          clearForm={clearForm}
          details={details}
          onSuccessCallback={requestValidateFuelCode}
          onActiveStepCB={activeStepCB}
        />
      )}
      <AnnounceVoidTransaction />
    </div>
  );
};

export default ValidatePaymentModule;
