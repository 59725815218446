import { Intro, DataTable, Text, Product, PopOverMenu, PopOver, Pill } from "components/commons";
import { StationContext } from "contexts";
import { useApi, useExport, useFilter, useModal, useMount } from "hooks";
import React, { useCallback, useContext } from "react";
import PaymentHistoryFilter from "./payment-history-filter";
import { paymentHistoryState } from "./payment-history-filter.state";
import styles from "./payment-history.module.scss";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import locale from "localization";
import { DateTime, Products, SettlementStatus } from "enums";
import { updateRedemptionRemarks, voidRedemption } from "apis/redemption.api";
import { useState } from "react";
import { useMemo } from "react";
import moment from "moment";
import {
  prettifyProduct,
  prettifyRedemptionStatus,
  prettifySettlementStatus,
  prettifyVoidTransactionValue,
} from "utils/pretty.utils";
import { formatAmount, formatVolume } from "utils";
import RedemptionStatus from "enums/redemption-status";
import VoidTransactionModal from "../payment-history-business/void-transaction-modal";
import ConfirmModal from "components/modals/confirm-modal/confirm-modal";
import SuccessModal from "components/modals/success-modal/success-modal";
import { generateStationReport } from "apis/generate-report.api";
import ReceiptGuideModal from "./receipt-guide-modal";

const PaymentHistoryModule = ({
  requestApi,
  title,
  columns,
  result,
  placeholder,
  onSearchValidation,
}) => {
  const [remarks, setRemarks] = useState();
  const [value, setValue] = useState("");

  const { station } = useContext(StationContext);
  const { stationId } = station;

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    submitFilter,
    submittedFilter,
    clearFilter,
    filterCount,
  } = useFilter(paymentHistoryState(stationId));

  const updateRemarksApi = useApi({
    api: updateRedemptionRemarks,
    modalError: true,
    pageError: false,
  });

  const {
    request: getPaymentHistoryRequest,
    loading: fetchingPaymentHistory,
    result: getPaymentHistoryResult = { redemptions: [], count: 0 },
  } = requestApi;

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidRedemption,
    modalError: true,
    pageError: false,
  });

  const voidTransactionModal = useModal();
  const receiptGuideModal = useModal();
  const confirmModal = useModal();

  useMount(() => {
    fetchPaymentHistory(requestState);
  });

  const fetchPaymentHistory = useCallback(
    (rs) => {
      const val = submitFilter(rs);
      getPaymentHistoryRequest({
        ...val,
        productCodes: val.productCodes.join(","),
        redemptionStatuses: val.redemptionStatuses.join(","),
        settlementStatuses: val.settlementStatuses.join(","),
      });
    },
    [getPaymentHistoryRequest, submitFilter]
  );

  const onVoidTransactionCb = useCallback(
    async ({ redemptionItem, value }) => {
      const { mobileNumber } = await voidRequest({
        redemptionId: redemptionItem.redemptionId,
        remarks: value,
      });
      voidTransactionModal.close();
      fetchPaymentHistory(requestState);
      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisTransactionHasBeenVoided}
            items={[
              <b>{formatVolume(redemptionItem?.redemption?.literVolume)}</b>,
              <b>{`${mobileNumber}.`}</b>,
            ]}
          />
        ),
        loading: voidingRequest,
        redemptionItem,
      });
      setRemarks("");
    },
    [
      voidRequest,
      voidTransactionModal,
      fetchPaymentHistory,
      voidingRequest,
      confirmModal,
      requestState,
    ]
  );

  const data = useMemo(() => {
    const { redemptions = [] } = requestApi?.result || {};

    if (redemptions && redemptions.length > 0) {
      const preparedData = redemptions.map((d) => {
        const map = new Map();
        map.set("redemptionId", d.redemptionId);
        map.set("fuelCode", d.fuelCode);
        map.set(
          "redeemedAt",
          <div className="flex" style={{ flexDirection: "column" }}>
            <Text>{moment(d.redeemedAt).format(DateTime.A)}</Text>
            <Text className={styles.ml}>{moment(d.redeemedAt).format(DateTime.B)}</Text>
          </div>
        );
        map.set(
          "product",
          <Product
            grass={d.productCode === Products.Gas91}
            salsa={d.productCode === Products.Gas95}
            deepBlue={d.productCode === Products.Gas97}
            cheddar={d.productCode === Products.Diesel}
          >
            {prettifyProduct(d.productCode)}
          </Product>
        );

        map.set("volume", formatVolume(d.literVolume));
        map.set("averageCreditPrice", formatAmount(d.averageCreditPrice));
        map.set("totalCreditAmount", formatAmount(d.totalCreditPrice || 0));
        // map.set("orNumber", <Text className={styles.overflow}>{d.orNumber}</Text>);
        map.set(
          "status",
          <Pill
            grass={d.redemption?.status === RedemptionStatus.Success}
            cement={d.redemption?.status === RedemptionStatus.Voided}
          >
            {prettifyRedemptionStatus(d.redemption?.status)}
          </Pill>
        );
        map.set(
          "settlementStatus",
          <>
            <Pill
              grass={d.redemption.settlementStatus === SettlementStatus.Processed}
              cyan={d.redemption.settlementStatus === SettlementStatus.ForSettlement}
              sky={d.redemption.settlementStatus === SettlementStatus.ForProcessing}
            >
              {prettifySettlementStatus(d.redemption.settlementStatus)}
            </Pill>
            {d.redemption.settlementStatus === SettlementStatus.ForProcessing ||
            d.redemption.settlementStatus === SettlementStatus.Processed ? (
              <Text className={styles.subBusinessId}>{d.redemption.settlementId}</Text>
            ) : (
              <Text className={styles.subBusinessId}>--</Text>
            )}
          </>
        );
        map.set(
          "remarks",
          d.redemption?.remarks ? (
            <PopOver content={<div className={styles.remarks}>{d.redemption?.remarks}</div>}>
              <div className="link">View</div>
            </PopOver>
          ) : (
            ""
          )
        );

        const options = [];
        if (
          d.redemption.status === RedemptionStatus.Success &&
          moment().format("MM YYYY") === moment(d.redemption.redeemedAt).format("MM YYYY")
        ) {
          options.push({
            content: locale.voidTransaction,
            onClick: () => {
              setRemarks("");
              voidTransactionModal.show({
                redemptionItem: d,
                title: `${locale.voidTransaction}?`,
                description: (
                  <Text align="center">
                    <locale.Populate
                      text={
                        d.redemption?.settlementId
                          ? d.redemption?.settlementStatus === SettlementStatus.ForProcessing
                            ? locale.voidingThisTransactionSettlementProcessing
                            : locale.voidingThisTransactionSettlementProcessed
                          : locale.voidingThisTransaction
                      }
                      items={[<b>{formatVolume(d?.redemption?.literVolume)}</b>]}
                    />
                  </Text>
                ),
                label: `${locale.reason}/${locale.remarks}`,
                actionText: locale.voidTransaction,
                submit: (value) => {
                  onVoidTransactionCb({
                    redemptionItem: d,
                    value,
                  });
                },
              });
            },
          });
        }

        if (d.redemption.status === RedemptionStatus.Voided) {
          options.push({
            content: locale.editRemarks,
            onClick: () => {
              setRemarks(d.redemption?.remarks);
              setValue(prettifyVoidTransactionValue(d.redemption?.remarks));
              voidTransactionModal.show({
                redemptionItem: d,
                title: locale.editRemarks,
                description: null,
                label: `${locale.reason}/${locale.remarks}`,
                actionText: locale.save,
                submit: async (value) => {
                  await updateRemarksApi.request({
                    id: d.redemptionId,
                    remarks: value,
                  });
                  voidTransactionModal.close();
                  setRemarks("");
                  fetchPaymentHistory(requestState);
                },
              });
            },
          });
        }

        options.push({
          content: locale.viewReceiptGuide,
          onClick: () => {
            setRemarks("");
            receiptGuideModal.show({
              redemptionItem: d,
              title: `${locale.receiptGuide}`,
              description: "",
              label: `${locale.reason}/${locale.remarks}`,
            });
          },
        });

        if (options.length) {
          map.set(
            "options",
            <PopOverMenu options={options}>
              <MoreVertIcon className={styles.icon} />
            </PopOverMenu>
          );
        } else {
          map.set(
            "options",

            <MoreVertIcon className={styles.iconDisabled} />
          );
        }

        return map;
      });
      return preparedData;
    }
    return [];
  }, [
    requestApi,
    onVoidTransactionCb,
    voidTransactionModal,
    receiptGuideModal,
    fetchPaymentHistory,
    requestState,
    updateRemarksApi,
  ]);

  const onDateRangeCb = useCallback(
    (value, dirty) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate, page: 1 });
      // fetchPaymentHistory(requestState);
    },
    [modifyFilters]
  );

  const onProductChangeCb = useCallback(
    (productCodes) => {
      modifyFilters({ productCodes, page: 1 });
      // fetchPaymentHistory(requestState);
    },
    [modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1, perPage: 10 });
      fetchPaymentHistory(requestState);
    },
    [modifyFilters, fetchPaymentHistory]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchPaymentHistory(requestState);
    },
    [modifyFilters, fetchPaymentHistory]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchPaymentHistory(requestState);
    },
    [modifyFilters, fetchPaymentHistory]
  );

  const exportReport = useExport({
    api: generateStationReport,
    reportType: "plc-redemption",
    hasModal: true,
    mappedFilterState: {
      ...requestState,
      platformType: "plc",
      redemptionStationId: stationId,
      redemptionStatuses: requestState?.redemptionStatuses.join(","),
      settlementStatuses: requestState?.settlementStatuses.join(","),
      productCodes: requestState?.productCodes.join(","),
    },
  });

  return (
    <div>
      <VoidTransactionModal
        {...voidTransactionModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={voidingRequest || updateRemarksApi.loading}
        onSubmit={voidTransactionModal.submit}
        value={value}
        setValue={setValue}
      />
      <ReceiptGuideModal
        {...receiptGuideModal}
        remarks={remarks}
        value={value}
        setValue={setValue}
      />
      <ConfirmModal {...confirmModal} />
      <SuccessModal {...exportReport?.successModalComponent} />
      <div>
        <Intro title={locale.populate(locale.paymentHistoryTitle, [title])} />
      </div>
      <div className={styles.filters}>
        <PaymentHistoryFilter
          modifyFilters={modifyFilters}
          filterCount={filterCount}
          placeholder={placeholder}
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onProductChange={onProductChangeCb}
          onSearchChange={(name, { value }) => {
            if (onSearchValidation && onSearchValidation(value)?.prevent) {
              return;
            }
            modifyFilter(name, { value });
          }}
          onSearch={onSearchCb}
          resetFilter={() => {
            const val = {
              productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
              endDate: null,
              startDate: null,
              ...submittedFilter,
            };
            modifyFilters(val);
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchPaymentHistory(requestState);
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchPaymentHistory(requestState);
          }}
          {...exportReport}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingPaymentHistory}
          page={filterState.page}
          perPage={filterState.perPage}
          columns={columns}
          data={data}
          dataCount={getPaymentHistoryResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
    </div>
  );
};

export default PaymentHistoryModule;
