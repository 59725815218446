const Path = {
  Slash: "/",
  Auth: "/auth",
  ForgotPassword: "/auth/forgot",
  ResetPassword: "/auth/reset",
  Download: "/download",
  Payment: "/payment",
  ValidatePayment: "/payment/validate",
  PaymentHistory1: "/payment/history",
  PaymentHistory: "/payment-history",
  PaymentHistoryBusiness: "/payment-history/business",
  PaymentHistoryConsumer: "/payment-history/consumer",
  ActivateAccount: "/activate-account",
  SelectStation: "/select-station",
  SettlementHistory: "/settlement/history",
  Inbox: "/inbox",
  ViewSettlementHistoryById: (id) => `/settlement/history/${id}/view`,
  ViewSettlementHistory: "/settlement/history/:id/view",
  PayWithPeso: "/payment-history/pay-with-peso",
  NonFuel: "/payment-history/non-fuel",
  PremiumPayout: "/premium-payout",
  ViewPremiumPayoutById: (id) => `/premium-payout/${id}/view`,
  ViewPremiumPayout: "/premium-payout/:id/view",
  AccessAuthorization: "/access-authorization",
  UserAccess: "/access-authorization/user-access",
  OtherServices: `/other-services`,
  LubeServOnSiteBookings: `/other-services/lubeserv-on-site-bookings`,
};

export default Path;
