import { locale } from "localization/en";

export const LitreVolumeState = (validations = [], props) => ({
  value: "",
  name: "literVolume",
  label: locale.actualVolumeDispensed,
  note: locale.pleaseWaitForGasUpToFinish,
  placeholder: "0.000 L",
  validations: [...validations],
  ...props,
});
