import locale from "localization";

export const settlementHistoryColumns = [
  { key: "dateProcessed", text: `${locale.dateProcessed}/${locale.settlementId}`, width: "10%"  },
  { key: "redemptionStation", text: `${locale.redemptionStation}/${locale.stationBusinessName}`, width: "13%" },
  { key: "totalLiters", text: locale.totalLiters, width: "10%" },
  { key: "totalPayPeso", text: locale.totalPayPeso, width: "10%" },
  { key: "totalMDRFee", text: locale.totalMDRFee, width: "10%" },
  { key: "totalDealerPayout", text: locale.totalDealerSettlementPayout, width: "10%" },
  { key: "dealerPayoutStatus", text: `${locale.dealerSettlementPayoutStatus}/${locale.date}`, width: "15%" },
  { key: "dealerRemarks", text: locale.remarks, width: "10%" },
  { key: "action", text: locale.action, width: "250px" },
];