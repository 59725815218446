import React from "react";
import { MenuItem, Select as MaterialSelect } from "@material-ui/core";
import styles from "./dropdown.module.scss";
import classNames from "classnames";
import { useCallback } from "react";

const Dropdown = ({
  value = "",
  error,
  placeholder,
  options = [],
  className,
  dropdownClassName,
  onChange,
}) => {
  const onChangeCb = useCallback(
    (e) => {
      if (onChange) {
        return onChange(e.target.value);
      }
    },
    [onChange]
  );

  return (
    <div className={classNames(styles.container, className)}>
      <MaterialSelect
        className={classNames(styles.dropdown, dropdownClassName)}
        error={error}
        value={value}
        variant="outlined"
        placeholder={placeholder}
        SelectDisplayProps={{ style: { fontSize: "14px", fontWeight: 500 } }}
        onChange={onChangeCb}
      >
        {options.map((o) => {
          return (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          );
        })}
      </MaterialSelect>
    </div>
  );
};

export default Dropdown;
