import { FuelIcon, Title, Text, ActionButton } from "components/commons";
import React from "react";
import locale from "localization";
import { formatNumber } from "utils";
import styles from "./validate-payment-confirm-page.module.scss";
import { prettifyProduct, prettifyDispenseType } from "utils/pretty.utils";
import classNames from "classnames";

const ValidatePaymentConfirmPage = ({
  loading,
  dispensedTo,
  literVolume,
  details,
  confirm,
  handleBackProceedToPayment,
}) => {
  return (
    <div className={styles.confirmContainer}>
      <div className={styles.confirmation}>
        <Text className={styles.headerValidateTitle} strong>
          {locale.proceedwithPaymentQuestion}
        </Text>
        <Title medium className={styles.title}>
          <locale.Populate
            text={literVolume === 1 ? locale.nLiter : locale.nLiters}
            items={[formatNumber(literVolume)]}
          />
        </Title>
        <Text>
          <locale.Populate
            text={locale.ofText}
            className={styles.fuel}
            items={[
              <span className={styles.fuel}>
                <FuelIcon className={styles.icon} type={details.productCode} />
                {prettifyProduct(details.productCode)}
              </span>,
            ]}
          />
        </Text>
        <Text>
          {locale.isAboutToBeRedeemed} {locale.kindlyReviewTheDetails}
        </Text>
        <div className={styles.tableContainer}>
          <div className={styles.table}>
            <div className={styles.row}>
              <label>{locale.businessName}</label>
              <label>{details.fleet?.businessName || ""}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.fuelCode}</label>
              <label>{details.fuelCode}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.eligibleDriver}</label>
              <label>{details?.driverName || locale.any}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.driverId}</label>
              <label>{details?.driverLicenseId || locale.any}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.eligibleVehicle}</label>
              <label
                className={classNames({
                  italic: !details.vehiclePlateNumber,
                })}
              >
                {details.vehiclePlateNumber || locale.any}
              </label>
            </div>
            <div className={styles.row}>
              <label>{locale.vehicleModel}</label>
              <label
                className={classNames({
                  italic: !details.vehicleModel,
                })}
              >
                {details.vehicleModel || locale.any}
              </label>
            </div>
            <div className={styles.row}>
              <label>{locale.dispensedTo}</label>
              <label>{prettifyDispenseType(dispensedTo)}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.remarks}</label>
              <label className={classNames(styles.remarks, { italic: !details.remarks })}>
                {details.remarks || locale.any}
              </label>
            </div>
          </div>
        </div>
      </div>
      <ActionButton
        center
        secondaryIcon={<span className="icon-back" />}
        items={[
          {
            text: locale.back,
            onClick: () => {
              handleBackProceedToPayment();
            },
          },
          {
            text: locale.proceed,
            onClick: () => confirm(),
            loading,
          },
        ]}
      />
    </div>
  );
};

export default ValidatePaymentConfirmPage;
