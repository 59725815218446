import React from "react";
import PaymentHistory from "../payment-history/payment-history";
import locale from "localization";
import { getRedemptions } from "apis";
import { useApi } from "hooks";

const PaymentHistoryForConsumer = () => {
  const requestApi = useApi({
    api: getRedemptions,
    pageError: true,
    params: {
      platformType: "plc",
    },
  });

  const paymentHistoryColumns = [
    { key: "redemptionId", text: locale.redemptionId, width: "8%" },
    { key: "redeemedAt", text: locale.redemptionDate, width: "10%" },
    { key: "product", text: locale.product, width: "6%" },
    { key: "volume", text: locale.volume, width: "6%" },
    { key: "averageCreditPrice", text: locale.averageCreditPrice, width: "6%" },
    { key: "totalCreditAmount", text: locale.totalCreditAmount, width: "10%" },
    { key: "status", text: locale.status, width: "10%" },
    {
      key: "settlementStatus",
      text: `${locale.settlementStatus}/${locale.settlementId}`,
      width: "10%",
    },
    { key: "remarks", text: locale.remarks, width: "10%" },
    { key: "options", text: locale.actions, width: "5%" },
    // { key: "orNumber", text: locale.orNumber, width: "20%" },
  ];

  return (
    <PaymentHistory
      title={locale.consumer}
      requestApi={requestApi}
      columns={paymentHistoryColumns}
      result={requestApi?.result}
      placeholder={locale.redemptionId}
      onSearchValidation={(searchKey) => {
        if ((searchKey && !isNaN(Number(searchKey))) || !searchKey) {
          return null;
        }
        return {
          prevent: true,
        };
      }}
    />
  );
};

export default PaymentHistoryForConsumer;
