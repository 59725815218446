import { useCallback, useMemo, useState } from "react";
import { flags as backupFlags } from "utils/feature-flags";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import useMount from "./useMount";

const useFeatureFlag = () => {
  const [userEmail, setUserEmail] = useState(null);
  const backUpFeatureFlags = useMemo(() => {
    const ff = {};
    Object.keys(backupFlags).forEach((key) => {
      ff[key] = {
        enabled: backupFlags[key],
        users: "",
      };
    });
    return ff;
  }, []);
  const [flags, setFlags] = useState(backUpFeatureFlags);

  const init = useCallback(async () => {
    try {
      const db = firebase.database();
      const flagRef = db.ref("station");
      if (flagRef) {
        flagRef.on("value", (snap) => {
          setFlags(snap.val());
        });
      }
    } catch (e) {
      console.log("ERROR on fetching Feature Flags", e);
      if (!Object.keys(flags).length) {
        setFlags(backUpFeatureFlags);
      }
    }
  }, [flags, backUpFeatureFlags]);

  useMount(() => {
    init();
  });

  const featureFlags = useMemo(() => {
    const obj = {};
    Object.keys(flags).forEach((key) => {
      const flag = flags[key];
      let granted = false;
      if ((userEmail && flag.users?.split(",").includes(userEmail)) || flag.enabled) granted = true;
      obj[key] = granted;
    });
    return obj;
  }, [userEmail, flags]);

  return {
    hasFeature: (flag) => {
      return Object.keys(featureFlags).includes(flag) ? featureFlags[flag] : true;
    },
    setUserEmail: (u) => {
      setUserEmail(u);
    },
  };
};

export default useFeatureFlag;
