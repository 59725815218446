import locale from "localization";

export const nonFuelColumns = [
  {
    key: "paymentId",
    text: locale.locqPayID,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "createdAt",
    text: locale.dateOfTransaction,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "amount",
    text: locale.paymentAmount,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "status",
    text: `${locale.status}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "settlementStatus",
    text: `${locale.settlementStatus}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "settlementId",
    text: `${locale.settlementId}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "settlementPayee",
    text: `${locale.settlementPayee}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "action",
    text: `${locale.action}`,
    style: {
      minWidth: "120px",
    },
  },
];
