import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = {
  fuelCode: {
    name: "fuelCode",
    placeholder: "",
    value: "",
    validations: [
      Validation.required({ noMessage: true }),
      Validation.alphaNumeric({ removeLast: true }),
      Validation.minlength({ length: 10, noMessage: true }),
      Validation.maxlength({ length: 10, noMessage: true }),
    ],
    maxLength: 10,
    label: locale.fuelCode,
    upperCase: true,
    required: true,
  },
};
