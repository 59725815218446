import Validation from "utils/validation.utils";
import locale from "localization";
import { LitreVolumeState } from "states";
import { DispenseType } from "enums";

export const initialState = (state = {}) => {
  const {
    fuelCode = {
      name: "fuelCode",
      label: locale.fuelCode,
    },
    productType = {
      name: "productType",
      label: locale.productType,
    },
    dispensedTo = {
      name: "dispensedTo",
      required: true,
      value: DispenseType.Vehicle,
      label: locale.dispensedTo,
    },
    literVolume = LitreVolumeState(
      [
        Validation.required,
        Validation.min(1, {
          customMessage: locale.minimumAllowedRedemption1Liter,
        }),
      ],
      { required: true }
    ),
  } = state;
  return {
    fuelCode,
    productType,
    dispensedTo: {
      validations: [Validation.required],
      ...dispensedTo,
    },
    literVolume,
  };
};
