import { Modal, Field, Text } from "components/commons";
import locale from "localization";
import React from "react";
import styles from "./receipt-guide-modal.module.scss";
import moment from "moment";
import { DateTime } from "enums";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { formatAmount, capitalize } from "utils/text.utils";
import Big from "big.js";

const ReceiptGuideModal = ({
  close,
  active,
  loading,
  title,
  description,
  redemptionItem,
  label,
}) => {
  const vatDivide = 1.12;
  const volume = redemptionItem?.literVolume || 0;
  const priceLOCQUnitPrice = Number(redemptionItem?.redemption?.pumpPrice || 0);
  const unitPrice = redemptionItem?.averageCreditPrice || 0;
  const unitAmount = Number(Big(unitPrice).mul(volume));
  const priceLOCQAmount = Number(Big(priceLOCQUnitPrice).mul(volume));
  const discountPerUnit = Number(Big(priceLOCQUnitPrice)) - Number(Big(unitPrice));
  const discountPerTotal = Number(Big(priceLOCQAmount).round(2).minus(unitAmount).round(2));
  const vatableSales = Number(Big(priceLOCQAmount).round(2).div(vatDivide).round(2));
  const vatAmount = Number(Big(priceLOCQAmount).minus(vatableSales));
  const totalAmountDue = Number(Big(vatableSales).add(vatAmount));
  return (
    <Modal active={active} title={title} close={close} className={styles.modal}>
      <div className={styles.headerReceiptGuide}>
        <div className={styles.headerLeftPart}>
          <Field
            className={styles.fieldLabelGuide}
            labelClassName={styles.fieldLabelInfo}
            label={locale.transactionId}
            labelPosition="left"
            childrenClassName={styles.fieldContent}
          >
            <Text strong>{redemptionItem?.redemptionId}</Text>
          </Field>

          <Field
            className={styles.fieldLabelGuide}
            labelClassName={styles.fieldLabelInfo}
            label={locale.dateTime}
            labelPosition="left"
            childrenClassName={styles.fieldContent}
          >
            <Text strong>
              {moment(redemptionItem?.redeemedAt).format(DateTime.A)}{" "}
              {moment(redemptionItem?.redeemedAt).format(DateTime.B)}
            </Text>
          </Field>
        </div>
      </div>
      <TableContainer component={Paper} className={styles.tableReceiptGuide}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell>Volume</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Product Description</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{volume}</TableCell>
              <TableCell>{locale.liters}</TableCell>
              <TableCell>{capitalize(redemptionItem?.productCode)}</TableCell>
              <TableCell align="right">{formatAmount(unitPrice)}</TableCell>
              <TableCell align="right">{formatAmount(unitAmount)}</TableCell>
            </TableRow>
            <TableRow className={styles.removeBorderButton}>
              <TableCell colSpan={2}></TableCell>
              <TableCell align="right">
                <Text strong>{locale.discount}</Text>
              </TableCell>
              <TableCell align="right">{formatAmount(discountPerUnit)}</TableCell>
              <TableCell align="right">{formatAmount(discountPerTotal)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell align="right">
                <Text strong>{locale.priceLOCQUnitPrice}</Text>
              </TableCell>
              <TableCell align="right">{formatAmount(priceLOCQUnitPrice)}</TableCell>
              <TableCell align="right">{formatAmount(priceLOCQAmount)}</TableCell>
            </TableRow>
            <TableRow className={styles.removeBorderButton}>
              <TableCell className={styles.vatSpaceFirst} colSpan={2}></TableCell>
              <TableCell align="right" className={styles.vatSpaceFirst}>
                {locale.vatTableSales}
              </TableCell>
              <TableCell className={styles.vatSpaceFirst}></TableCell>
              <TableCell align="right" className={styles.vatSpaceFirst}>
                {formatAmount(vatableSales)}
              </TableCell>
            </TableRow>
            <TableRow className={styles.removeBorderButton}>
              <TableCell colSpan={2} className={styles.vatSpace}></TableCell>
              <TableCell align="right" className={styles.vatSpace}>
                {locale.vatExemptSales}
              </TableCell>
              <TableCell className={styles.vatSpace}></TableCell>
              <TableCell align="right" className={styles.vatSpace}>
                {formatAmount(0)}
              </TableCell>
            </TableRow>
            <TableRow className={styles.removeBorderButton}>
              <TableCell className={styles.vatSpace} colSpan={2}></TableCell>
              <TableCell align="right" className={styles.vatSpace}>
                {locale.vatZeroRatedSales}
              </TableCell>
              <TableCell className={styles.vatSpace}></TableCell>
              <TableCell align="right" className={styles.vatSpace}>
                {formatAmount(0)}
              </TableCell>
            </TableRow>
            <TableRow className={styles.removeBorderButton}>
              <TableCell className={styles.vatSpace} colSpan={2}></TableCell>
              <TableCell align="right" className={styles.vatSpace}>
                {locale.vatAmount}
              </TableCell>
              <TableCell className={styles.vatSpace}></TableCell>
              <TableCell align="right" className={styles.vatSpace}>
                {formatAmount(vatAmount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell align="right">
                <Text strong>{locale.totalAmountDue}</Text>
              </TableCell>
              <TableCell></TableCell>
              <TableCell align="right">
                <Text strong>{formatAmount(totalAmountDue)}</Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Modal>
  );
};

export default ReceiptGuideModal;
