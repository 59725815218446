const StationReport = {
  ConsumerRedemption: "plc-redemption",
  BusinessRedemption: "plb-redemption",
  Payment: "payment",
  MerchantPayment: "merchant-payment",
  LubeservOnSite: "lubeserv-on-site",
};

const ReportType = {
  CashIn: "cashin",
  BuyFuel: "buy-fuel",
  Redemption: "redemption",
  FleetAccount: "fleet-account",
  FleetPrices: "fleet-prices",
  Settlements: "settlements",
  RedemptionItem: "redemption-item",
  PricingHistory: "pricing-history",
  FuelCode: "fuel-code",
  FuelCodes: "fuel-codes",
  Mops: "mops",
  VoucherClaim: "voucher-claim",
  NewCustomerVoucher: "new-customer-voucher",
  PriceBuildup: "price-buildup",
  PriceMovement: "price-movement",
  StationAccount: "station-account",
  CustomerList: "customer-list",
  VoucherCampaign: "voucher-campaign",
  DiscountCampaign: "discount-campaign",
  RfidTopup: "rfid-topup",
  Payment: "payment",
  MerchantPayment: "merchant-payment",
  KYCSubmission: "kyc-submission",
  SupplierWPP: "supplier-wpp",
  ConsumerRedemption: "plc-redemption",
  BusinessRedemption: "plb-redemption",
  LubeservOnSite: "lubeserv-on-site",
};

export { StationReport, ReportType };
