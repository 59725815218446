import { Autocomplete, DateRange, Field, Filter, SearchField, Button } from "components/commons";
import locale from "localization";
import { SettlementStatus } from "enums";
import { prettifySettlementStatus } from "utils";
import styles from "./settlement-history-filter.module.scss";

const SettlementHistoryFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  resetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exporting,
  exportReport
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          filterCount={filterCount}
          submit={applyFilter}
          clear={applyClearFilter}
          close={resetFilter}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            label={locale.dateProcessed}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          {[
            {
              name: "dealerSettlementStatuses",
              label: locale.dealerSettlementPayoutStatus,
              value: filterState.dealerSettlementStatuses,
            },
            {
              name: "discountVoucherStatuses",
              label: locale.discountVoucherStatus,
              value: filterState.discountVoucherStatuses,
            },
          ].map(({ name, label, value }, key) => (
            <Field
              key={key}
              className={styles.fieldSelect}
              label={label}
              labelClassName={styles.fieldLabel}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                value={value}
                name={name}
                placeholder={"Select"}
                multiple
                hasAll
                options={[
                  {
                    label: prettifySettlementStatus(SettlementStatus.ForProcessing),
                    value: SettlementStatus.ForProcessing,
                  },
                  {
                    label: prettifySettlementStatus(SettlementStatus.Processed),
                    value: SettlementStatus.Processed,
                  },
                ]}
                onChange={(_, { value }) => modifyFilter({ [name]: value })}
              />
            </Field>
          ))}
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={locale.stationBusinessNameRedemptionStation}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default SettlementHistoryFilter;
